import {checkNull} from '@/helpers'

export default {
    name: 'dashboard',
    data() {
        return {
            cdn_url: window.APP_CONFIG.CDN_URL,
            sub_menu: [
                { name: 'My Profile', route: 'user-profile' },
                { name: 'Add Photos', route: 'user-photos' },
                { name: 'Messages', route: 'user-messages', sub_title: null },
                { name: 'Favorites', route: 'user-favorites' },
                { name: 'Search', route: 'user-search-profiles' },
                { name: 'Settings', route: 'user-settings' },
            ],
            default_avatar_male: `${window.APP_CONFIG.CDN_URL}/images/male.png`,
            notif_audio: null,
            is_dev: process.env.NODE_ENV !== 'production',
        }
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        mobileNoPad: {
            type: Boolean,
            default: false
        },
        iconRight: {
            type: String,
            default: 'null'
        },
        iconInTitle: {
            type: String,
            default: ''
        },
        darkBg: {
            type: Boolean,
            default: true
        },
        hasTopNavBar: {
            type: Boolean,
            default: true
        },
        fixedFooter: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        populateUnreadMessage(val) {
            let item = this.sub_menu.find( (val,key) => {
                if(val.route == 'user-messages') return val
            })
            item.sub_title = !val ? null : val.toString()
        },
        notifEventHandler(e) {
            let text = ''
            let message = (checkNull(e.message)) ? e.message.substring(0,50) : ''

            switch(e.type) {
                case 'favorite': text = this.$t('Has added you to favorites'); break;
                case 'visit': text = this.$t('Is looking at your profile'); break;
                case 'sticker': text = this.getIcebreaker(message, 'label'); break;
                default: text = message.length == 50 ? `${message}...` : message;
            }

            EventBus.$emit('new-message')
            let notif_sound_enabled = this.$root.$children[0].$refs['user-notif'].notif_sound
            if(this.notif_audio && notif_sound_enabled && typeof this.notif_audio.play == 'function') this.notif_audio.play()

            this.$notify({
                group: 'user-notif',
                title: e.sender_nick_name,
                text,
                duration: 15000,
                data: {
                    image: e.sender_pic,
                    type: e.type,
                    nick_name: e.sender_nick_name,
                    customer_support: e.customer_support || false
                }
            })
        },
        getIcebreaker(sticker_key, key) {
            var icebreaker = this.icebreakers.find((val) => {
                return val.key == sticker_key
            })

            return checkNull( icebreaker ) ? icebreaker[key] : ''
        },
        loadListener(profile) {
            let c_urser_notif = this.$root.$children[0].$refs['user-notif']
            if(c_urser_notif) {
                let is_loaded = c_urser_notif.is_loaded
                if(!is_loaded && checkNull(profile) && checkNull(profile.id)) {
                    c_urser_notif.toggleNotifListener()
                    window.Echo.private(`subscribe-user-notification-${profile.id}`)
                        .listen('UserNotification', this.notifEventHandler)
                }
            }
        },
        handleRightClick(e) {
            if (!this.is_dev) {
                e.preventDefault()
            }
        },
        justLoggedin() {
            if(this.$store.getters['auth/justLoggedIn']) {
                this.$store.commit('auth/setJustLoggedIn', false)
                // only to execute once per login
            }
        },
        checkSeasonalDiscount() {
            if(!this.$store.getters['payment/closeButtonDiscountPopup']) {
                this.$store.dispatch('payment/getSeasonalDiscount')
                .then(res => {
                    this.$store.commit('payment/setOpenDiscountPopup', true)
                }, err => console.log('error:', err))
            }
        }
    },
    computed: {
        credits() {
            return this.$store.getters['auth/credits']
        },
        subscription_type() {
            return this.$store.getters['auth/subscriptionType']
        },
        unread_messages() {
            let i = this.$store.getters['auth/profile']['welcome_message'] === 1 ? 1 : 0
            let n = parseInt(this.$store.getters['auth/unreadNotifications'])
            return parseInt(this.$store.getters['auth/unreadMessages']) + i + n
        },
        icebreakers() {
            return this.$store.getters['message/icebreakers']
        },
        profile() {
            return this.$store.getters['auth/profile']
        },
        gender() {
            return this.$store.getters['auth/profile']['sex']
        },
        showDesktop() {
            return this.$root.$children[0].width > 1199
        },
        isAuthenticated() {
            return this.$store.getters['auth/isAuthenticated']
        }
    },
    created() {
        this.notif_audio = new Audio(`${this.cdn_url}/media/notif.mp3`)
        window.addEventListener("contextmenu", this.handleRightClick)
    },
    mounted() {
        this.populateUnreadMessage(this.unread_messages)
        if(this.profile) this.loadListener(this.profile)
        this.justLoggedin()
        if(this.isAuthenticated) this.checkSeasonalDiscount()
    },
    beforeDestroy() {
        if(this.profile) window.Echo.leave(`subscribe.user.${this.profile.id}`)
        window.removeEventListener("contextmenu", this.handleRightClick)
    },
    watch: {
        unread_messages(to,from) {
            this.populateUnreadMessage(to)
        },
        profile(to, from) {
            this.loadListener(to)
        },
        '$route' (to, from) {
            if(checkNull(from.name) && from.name != 'home') this.loadListener()
        }
    }
}

<template>
  <div :class="`dashboard main-con ${!showDesktop ? 'mobile' : ''}`">
    <template v-if="showDesktop">
      <div class="banner-wrapper">
        <main-header />
      </div>
      <div class="content-con">
        <div class="con-wrapper">
          <div class="wrappers">
            <div class="sidebar-wrapper">
              <profile-sidebar />
            </div>
            <slot />
          </div>
        </div>
      </div>
      <main-footer />
    </template>
    <template v-else>
      <main-header
        is-mobile
        @sstate="handleSidebarState"
        :class="[{'intersected': scrollTopPos > 10 }]"
      />
      <mobile-menu ref="mobile-menu" :is-landscape="isLandscape" :init-opened="mobile_menu_opened"/>
      <div
        :class="
          `content-con mobile
          ${sidebar_isactive ? 'sidebar-active' : '' }
        `"
      >
        <slot />
      </div>
      <base-menu
        :key="renderMenuKey"
        :class="[{'show-nav': showNav}, 'bottom-nav', 'mobile-main-nav']"
        item-class="flex-direction-column"
        :menu="mobile_bottom_menu"
      />
    </template>
  </div>
</template>

<script>
    import BaseMenu from '@theme-views/common/BaseMenu'
    import BaseImage from '@views/common/BaseImage'
    import MainHeader from '@theme-views/common/MainHeader'
    import MainFooter from '@theme-views/common/MainFooter'
    import MobileMenu from '@theme-views/common/MobileMenu'
    import ProfileSidebar from '@theme-views/user/partials/ProfileSidebar'
    import Dashboard from '@views/user/layouts/Dashboard/dashboard'
    import {handleBackBtn} from '@/helpers'

    export default {
        mixins: [Dashboard],
        props: {
          backFallbackRoute: {
              type: Object,
              default: () => {
                  {}
              },
              showQuickSearch: {
                type: Boolean,
                default: false
              }
          },
          backCallback: {
              type: Function,
              default: null
          },
          noTopNav: {
              type: Boolean,
              default: false
          },
          scrollTopPos: {
            type: Number,
            default: 0
          }
        },
        data() {
            return {
                isLandscape: null,
                sub_menu: [
                    { name: 'My Profile', route: 'user-profile' },
                    { name: 'Messages', route: 'user-messages', sub_title: null },
                    { name: 'Favorites', route: 'user-favorites' },
                    { name: 'Search', route: 'user-search-profiles' },
                    { name: 'Settings', route: 'user-settings' },
                ],
                renderMenuKey: 0,
                mobile_bottom_menu: [
                    {
                      name:'Home',
                      route: 'user-home',
                      svg_icon: {
                        name: 'home',
                        width: 27,
                        height: 27,
                        color: '#c5c5c5'
                      }
                    },
                    {
                      name:'Messages',
                      route: 'user-messages',
                      svg_icon: {
                        name: 'envelope',
                        id: 2,
                        width: 29,
                        height: 28,
                        color: '#c5c5c5'
                      }
                    },
                    {
                      name:'Favorites',
                      route: 'user-favorites',
                      svg_icon: {
                        name: 'star',
                        width: 28,
                        height: 28,
                        color: '#c5c5c5'
                      }
                    },
                    {
                      name:'Profile',
                      route: 'user-mobile-home',
                      svg_icon: {
                        name: 'profile',
                        width: 24,
                        height: 28,
                        color: '#c5c5c5'
                      }
                    }
                ],
                mobile_menu_opened: false,
                sidebar_isactive: false,
                lastScrollPosition: 0,
                scrollOffset: 40,
                showNav: true
            }
        },
        methods: {
            openMobileMenu() {
                if(this.$refs['mobile-menu']) {
                    this.$refs['mobile-menu'].openMenu()
                    this.mobile_menu_opened = this.$refs['mobile-menu'].opened
                }
            },
            handleBackClick() {
                if(this.backCallback) {
                    this.backCallback()
                } else {
                    handleBackBtn(this.$router, this.routeFrom, this.backFallbackRoute)
                }
            },
            handleSidebarState(state) {
              this.sidebar_isactive = state
            },
            onScroll() {
              if (this.scrollTopPos < 0) {
                return
              }
              if (Math.abs(this.scrollTopPos - this.lastScrollPosition) < this.scrollOffset) {
                return
              }
                this.showNav = this.scrollTopPos < this.lastScrollPosition
                this.lastScrollPosition = this.scrollTopPos
            },
            detectDevice() {
              if(this.showDesktop) {
                this.isLandscape = false
              } else {
                this.isLandscape = window.matchMedia("(orientation: portrait)").matches ? false : true
              }
            },
            populateUnreadMessage(val) {
                this.addUnreadCount(this.mobile_bottom_menu, val)
                this.renderMenuKey ++
            },
            addUnreadCount(menu, val) {
                let item = menu.find( (val,key) => {
                    if(val.route == 'user-messages') return val
                })
                item.sub_title = !val ? null : val.toString()
                if(item.icon_class && val) {
                    item.icon_class = 'icon icon-message-full'
                } else if(item.icon_class) {
                    item.icon_class = 'icon icon-message-grey'
                }
            },
        },
        computed: {
          routeFrom() {
              return this.$store.getters['pub/routeFrom']
          },
          unread_messages() {
            let i = this.$store.getters['auth/profile']['welcome_message'] == 1 ? 1 : 0
            return parseInt(this.$store.getters['auth/unreadMessages']) + i
          },
        },
        created() {
          this.scrollTopPos = 0
          this.detectDevice()
          this.lastScrollPosition = this.scrollTopPos
          EventBus.$on('orientation-change', this.detectDevice)
          window.addEventListener("contextmenu", this.handleRightClick)
        },
        beforeDestroy() {
          EventBus.$off('orientation-change', this.detectDevice)
          window.removeEventListener("contextmenu", this.handleRightClick)
        },
        mounted() {
            this.populateUnreadMessage(this.unread_messages)
        },
        watch: {
          scrollTopPos() {
            this.onScroll()
          },
          unread_messages(to,from) {
            this.populateUnreadMessage(to)
          },
        },
        components: {
            MainHeader,
            MainFooter,
            MobileMenu,
            BaseMenu,
            BaseImage,
            ProfileSidebar
        }
    }
</script>

import { checkNull } from '@/helpers'

export default {
    name: 'profile-sidebar',
    data() {
        return {
            default_avatar_male: `${window.APP_CONFIG.CDN_URL}/images/male.png`,
            default_avatar_female: `${window.APP_CONFIG.CDN_URL}/images/female.png`,
            main_menu: [
                { name: 'Home', route: 'user-home', icon_class: 'icon icon-home' },
                { name: 'Messages', route: 'user-messages', icon_class: 'icon icon-envelope', sub_title: null},
                { name: 'Visits', route: 'user-visits', icon_class: 'icon icon-eye' },
                { name: 'Search', route: 'user-search-profiles', icon_class: 'icon icon-search' },
                { name: 'Favorites', route: 'user-favorites', icon_class: 'icon icon-favorites' },
                { name: 'Likes', route: 'user-other-favorites', icon_class: 'icon icon-other-favorites' },
                { name: 'Membership', route: 'user-credits', icon_class: 'icon icon-heart', sub_title: null, sub_title_class: 'subscription-type'},
            ],
            webcam_url: window.APP_CONFIG.WEBCAM_URL,
            banners: [],
        }
    },
    methods: {
        populateUnreadMessage(val) {
            let item = this.main_menu.find( (val,key) => {
                if(val.route == 'user-messages') return val
            })
            item.sub_title = !val ? null : val.toString()
        },
        populateSubscriptionType(val) {
            this.main_menu[6].name = 'Membership'
            this.main_menu[6].sub_title = !val ? 'Basic' : val.toString()
        },
        populateCredits(val) {
            if(val) {
                this.main_menu[6].name = 'Credits'
                this.main_menu[6].sub_title = this.credits.toString()
                this.main_menu[6].sub_title_class = null
            } else {
                this.populateSubscriptionType(this.subscriptionType)
            }
        },
    },
    computed: {
        avatarImage() {
            let p_id = this.$store.getters['auth/profile']['profile_image_id']
            if( checkNull( p_id ) ) return this.$store.getters['auth/profile']['profile_image']

            let b_gender = this.$store.getters['auth/profile']['sex']
            return checkNull(b_gender) ? (b_gender == 'male' ? this.default_avatar_male : this.default_avatar_female) : this.default_avatar_male
        },
        avatarIsPrivate() {
            return this.$store.getters['auth/profile']['profile_image_blur']
        },
        topProfiles() {
            let profiles = []
            if(this.$store.getters['profile/profiles'].length) {
                this.$store.getters['profile/profiles'].forEach((val, key) => {
                    if(profiles.length < 5) {
                        profiles.push(val)
                    }
                })
            }

            return profiles
        },
        isAvatarImageDefault() {
            let s_profile_image = this.$store.getters['auth/profile']['profile_image']
            if(s_profile_image === '') return false

            let a_break = s_profile_image.split('/')
            if(a_break[a_break.length-2] == 'uploads') return false

            return true
        },
        isPremium() {
            return this.$store.getters['auth/premium']
        },
        subscriptionType() {
            return this.$store.getters['auth/subscriptionType']
        },
        unreadMessages() {
            let i = this.$store.getters['auth/profile']['welcome_message'] == 1 ? 1 : 0
            let n = parseInt(this.$store.getters['auth/unreadNotifications'])
            return parseInt(this.$store.getters['auth/unreadMessages']) + i + n
        },
        gender() {
            return this.$store.getters['auth/profile']['sex']
        },
        credits() {
            return this.$store.getters['auth/credits']
        },
        withBlur() {
            return !Boolean(this.$store.getters['auth/profile']['remove_blur'])
        },
        locale() {
            return Vue.i18n.locale().toUpperCase()
        },
        multiBannners() {
            return [
                { img: `/images/ads/penomet/${this.locale}_ad.jpg`, link: 'https://mixi.mn/?a=155960&c=8136&p=r' },
                { img: `/images/ads/vilasil/${this.locale}_ad.jpg`, link: 'https://mixi.mn/?a=155960&c=7587&p=r' }
            ]
        }
    },
    created() {
        if(this.webcam_url) {
            this.main_menu.push({ name: 'Cams', icon_class: 'icon icon-light-cam', action_name: 'redirectWebCamUrl', href: '/cams' })
        }
    },
    mounted() {
        this.populateCredits( this.gender != 'female' )
        this.populateUnreadMessage(this.unreadMessages)
    },
    watch: {
        subscriptionType(to,from) {
            this.populateSubscriptionType(to)
        },
        unreadMessages(to,from) {
            this.populateUnreadMessage(to)
        },
        credits(to, from) {
            this.populateCredits(to && this.gender != 'female')
        },
        gender(to, from) {
            this.populateCredits(to != 'female')
        }
    }
}

<template>
    <div :class="[{'mobile-layout': isMobile}, 'main-header con-wrapper']" @click.prevent.stop="handleHeaderClick">
        <div class="wrapper" v-if="!isMobile">
            <div class="logo-con">
              <router-link :to="{ name: 'home' }">
                  <img class="img-responsive" :src="`${icdn_url}/desktop-logo.png`" :alt="`Logo ${site_name}`" v-cloak>
              </router-link>
            </div>
            <div class="login-con" v-if="!isAuthenticated">
                <span>{{ 'Already a member?' | translate }}</span>
                <div class="btn-con">
                    <button data-cy="btn-login" class="btn" @click="$router.push({ name: 'login' })">
                        <span>{{ 'Login' | translate }}</span>
                    </button>
                </div>
            </div>
            <div class="user-nav" v-if="isAuthenticated">
                <div class="btn-con">
                    <div class="credit-count">
                      <label for="credit-count" class="page-header-2 color-gray">{{ 'My credits' | translate }} </label>
                      <div name="credit-count" class="credit-count-content">
                        <svg width="24" height="24" class="t-icon fire credit-count-icon"></svg>
                        <span class="credit-count-credits color-red page-header-2">
                          {{ credits.toLocaleString() }}
                        </span>
                      </div>
                    </div>

                    <button data-cy="btn-logout" class="btn logout-btn btn-t-gray" @click="() => { this.open_logout_confirm = !this.open_logout_confirm }">
                        <span>{{ 'Log out' | translate }}</span>
                        <svg height="16" width="16" class="t-icon exit"></svg>
                    </button>

                    <logout-confirm
                        v-if="open_logout_confirm"
                        @close="() => { this.open_logout_confirm = false }"
                    />
                </div>
            </div>
        </div>

        <!-- mobile layout starts -->
        <div class="wrapper" v-else>
            <div class="logo-con">
              <router-link :to="{ name: 'user-mobile-home' }">
                  <img class="img-responsive" :src="`${icdn_url}/desktop-logo.png`" :alt="`Logo ${site_name}`" v-cloak>
              </router-link>
            </div>
            <div class="user-nav" v-if="isAuthenticated">
                <div class="btn-con">
                    <div 
                      v-if="credits > 0 && this.$route.name !== 'user-mobile-home'" 
                      @click.stop="$router.push({name: 'user-credits'})"
                      name="credit-count" 
                      class="credit-count-content"
                    >
                      <svg width="24" height="24" class="t-icon fire credit-count-icon"></svg>
                      <span class="credit-count-credits color-red page-header-2">
                        {{ credits.toLocaleString() }}
                      </span>
                    </div>

                    <button v-if="credits <= 0 && this.$route.name !== 'user-mobile-home'" class="btn btn-credits btn-credits--mheader" @click="$router.push({ name: 'user-credits' })">
                      <svg width="18" height="18" class="t-icon fire"></svg>
                      <span>{{ 'Get Credits' | translate }}</span>
                    </button>

                    <!-- <span v-if="this.$route.name === 'user-mobile-home'" class="showcase-logout" @click="logout">
                      <svg height="22" width="22" class="t-icon exit"></svg>
                    </span> -->

                    <button
                      :class="[{'ding': unread_notifications > 0},'btn notification-btn']"
                      @click.prevent.stop="handleNotif"
                    >
                      <svg height="24" width="24" class="t-icon bell bell-icon"></svg>
                      <span
                        v-if="unread_notifications > 0"
                        class="unread-notif"
                      >
                        {{ unread_notifications }}
                      </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import MainHeader from '@views/common/MainHeader/main_header'
    import LogoutConfirm from '@theme-views/common/MainHeader/partials/LogoutConfirm'

    export default {
      mixins: [MainHeader],
      props: {
        isMobile: {
          type: Boolean,
          default: false
        }
      },
      components: {
        LogoutConfirm
      },
      data() {
          return {
            cdn_url: window.APP_CONFIG.CDN_URL,
            active: true
          }
      },
      computed: {
        credits() {
          return this.$store.getters['auth/credits']
        },
        unread_notifications() {
          return this.$store.getters['auth/unreadNotifications']
        },
        isLogoutRoute() {
            return this.$route.name === 'logged-out'
        }
      },
      methods: {
        handleNotif() {
          this.active = true
          EventBus.$emit('open-sidebar')
        },
        handleNotifClose() {
          this.active = false
        },
        handleHeaderClick() {
          this.$root.$children[0].$el.scrollTop = 0
        },
        logout() {
          this.$store.dispatch('auth/logout')
            .then(res => {
                this.$router.push({ name: 'logged-out', params: { status: 'success', fromRemoveAuth: true } });
            }, res =>{
                this.$router.push({ name: 'logged-out', params: { status: 'success', fromRemoveAuth: true } });
            });
        },
      },
      created() {
        EventBus.$on('close-sidebar', this.handleNotifClose)
      },
      beforeDestroy() {
        EventBus.$off('close-sidebar', this.handleNotifClose)
      }
    }
</script>

<style lang="scss" src="./main_header.scss"></style>

<template>
	<div class="base-menu">
		<ul>
			<li ref="menu_items" v-for="(item, index) in menu" v-if="!item.hide" :key="index">
				<a 
          :href="item.route" 
          :disabled="item.disabled" 
          :class="[itemClass]"
          :data-cy="item.test_id"
					v-if="item.isExternal && !item.action_name" 
          target="_blank"
        >
					<i v-if="item.icon_class" :class="item.icon_class">
						<span v-if="item.badge" class="badge">{{ item.badge }}</span>
					</i>
          <!-- icon -->
          <div v-if="item.svg_icon" class="icon-wrapper">
            <svg 
              :height="item.svg_icon.height" 
              :width="item.svg_icon.width"
              :class="`t-icon icon-svg ${item.svg_icon.name}`">
            </svg>
          </div>
          <!-- icon ends -->
					<span v-if="item.name" class="label page-header-2">{{ item.name | translate }}</span>

          <svg v-if="chevron" height="26" width="26" class="t-icon chevron-right chevron-icon icon-svg" />
				</a>
				<router-link
          :to="getBasicRoute(item)"
          :disabled="item.disabled"
          :class="[itemClass]"
          :data-cy="item.test_id"
					v-if="!item.isExternal && !item.action_name"
        >
            <i v-if="item.icon_class" :class="item.icon_class">
                <span v-if="item.badge" class="badge">{{ item.badge }}</span>
            </i>
            <!-- icon -->
            <div v-if="item.svg_icon" class="icon-wrapper">
              <svg 
                :height="item.svg_icon.height" 
                :width="item.svg_icon.width"
                :class="`t-icon icon-svg ${item.svg_icon.name}`">
              </svg>
            </div>
            <!-- icon ends -->
            <span v-if="item.name" class="label page-header-2">
              {{ item.name | translate }}
              <span
                v-if="item.sub_title"
                :class="`sub_title ${item.sub_title ? item.sub_title.toString().toLowerCase() : ''} ${item.sub_title_class ? item.sub_title_class : ''}`"
              >
                {{ item.sub_title | translate }}
              </span>
            </span>
           <svg v-if="chevron" height="26" width="26" class="t-icon chevron-right chevron-icon icon-svg" />
				</router-link>
				<a
          v-if="item.action_name"
          
          :href="item.href || '#'" :disabled="item.disabled"
          :class="[itemClass]"
          :data-cy="item.test_id"
					@click.prevent="handleAction(item.action_name, $event, item)"
        >
					<i v-if="item.icon_class" :class="item.icon_class">
						<span v-if="item.badge" class="badge">{{ item.badge }}</span>
					</i>
          <!-- icon -->
          <div v-if="item.svg_icon" class="icon-wrapper">
            <svg 
              :height="item.svg_icon.height" 
              :width="item.svg_icon.width"
              :class="`t-icon icon-svg ${item.svg_icon.name}`">
            </svg>
          </div>
          <!-- icon ends -->
					<span v-if="item.name" class="label page-header-2">{{ item.name | translate }}</span>
          <svg v-if="chevron" height="26" width="26" class="t-icon chevron-right chevron-icon icon-svg" />
				</a>
			</li>
		</ul>
		<slot></slot>
  </div>
</template>

<script>
    import BaseMenu from '@views/common/BaseMenu/base_menu'
    import {checkNull} from '@/helpers'

    export default {
        mixins: [BaseMenu],
        props: {
          chevron: {
            type: Boolean,
            default: false
          }
        },
        data() {
            return {
                menu_hovered: [],
                cdn_url: window.APP_CONFIG.CDN_URL
            }
        },
    }
</script>

<style lang="scss">
.base-menu {
	ul {
		padding: 0px;
		margin: 0px;
		li {
			a[disabled] {
				pointer-events: none;
				cursor: not-allowed;
				color: #B2B2B2;
			}
		}
	}
}
</style>
